<template>
  <div class="content-wrap">
    <div class="content-select" v-if="isRetail">
      <label>当前组织：</label>
      <organization-select :is-add-all="true" :model="selectForm"></organization-select>
    </div>
    <div :class="isRetail ? 'content-tab' : ''">
      <el-tabs v-model="activeName" @tab-click="handleInvoiceOpenTabsChange">
        <el-tab-pane label="原始订单" name="order" lazy>
          <electron-original-order :model="selectForm" v-if="activeName == 'order'"></electron-original-order>
        </el-tab-pane>
        <el-tab-pane label="待开发票" name="wait" lazy>
          <electron-wait-invoice v-if="isRetail && activeName == 'wait'" :billingTradeIds="billingTradeIds"></electron-wait-invoice>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import ElectronOriginalOrder from './electron/ElectronOriginalOrder';
import ElectronWaitInvoice from './electron/ElectronWaitInvoice';

import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import store from '@/store';

export default {
  name: 'ElectronInvoiceIssue',
  components: {
    OrganizationSelect,
    ElectronWaitInvoice,
    ElectronOriginalOrder
  },
  data() {
    return {
      activeName: '',
      selectForm: {
        orgId: localStorage.getItem('orgId') ? Number(localStorage.getItem('orgId')) : '',
        name: ''
      },
      billingTradeIds: ''
    };
  },
  watch: {
    'selectForm.orgId': {
      handler: (value) => store.commit('invoiceIssue/SET_CURRENT_ORGANIZATION', value),
      immediate: true
    }
  },
  computed: {
    isRetail() {
      return true;
    }
  },
  created() {
    this.activeName = 'order';
    const billingTradeIdsQuery = this.$route.query.billingTradeIds;
    if (billingTradeIdsQuery) {
      this.billingTradeIds = window.atob(billingTradeIdsQuery);
    }
  },
  methods: {
    // 发票开具Tabs切换
    handleInvoiceOpenTabsChange(tab) {
      this.activeName = tab.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.content-select {
  width: 280px;
  position: relative;
  top: 16px;
  left: calc(100% - 350px);
  z-index: 200;

  label {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
  }

  ::v-deep .el-input__inner {
    border: none;
    border-radius: 0;
  }
}

.content-tab {
  margin-top: -24px;
  z-index: 1;
}

::v-deep .el-tabs__item {
  margin-top: 7px;
  color: #333333;
  font-weight: 400;
  font-size: 16px;
}

::v-deep .el-tabs__item.is-active {
  color: #3d94ff;
}
</style>
