import {download, post} from './index.js'

/**
 * 导出全电发票数据
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export const exportElectronInvoice = (data) => {
    return download('/electron/exportElectronInvoice', data).then(res => res)
}

/**
 * 导入全电发票数据
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export const importElectronInvoice = (data) => {
    return post('/electron/importElectronInvoice', data).then(res => res)
}
