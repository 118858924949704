<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form class="select-form" ref="queryForm" :model="queryForm">
          <div>
            <el-form-item label="订单时间">
              <el-date-picker
                clearable
                size="mini"
                v-model="queryForm.orderTimeRange"
                type="daterange"
                range-separator=""
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="生成时间">
              <el-date-picker
                v-model="queryForm.createdRange"
                type="daterange"
                range-separator=""
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="业务单号" prop="dataId">
              <el-input v-model.trim="queryForm.dataId" placeholder="请输入业务单号" maxlength="30"
                        @keyup.enter.native="handleQuery"/>
            </el-form-item>
          </div>
          <div v-show="selectExpended">
            <el-form-item label="商品编码" prop="merchandiseCode">
              <el-input v-model.trim="queryForm.merchandiseCode" placeholder="请输入商品编码" maxlength="30"
                        @keyup.enter.native="handleQuery"/>
            </el-form-item>
            <el-form-item label="购方名称" prop="buyerName">
              <el-input v-model.trim="queryForm.buyerName" placeholder="请输入购方名称" maxlength="30"
                        @keyup.enter.native="handleQuery"/>
            </el-form-item>
            <el-form-item label="销方名称" prop="sellerName">
              <el-input v-model.trim="queryForm.sellerName" placeholder="请输入销方名称" maxlength="30"
                        @keyup.enter.native="handleQuery"/>
            </el-form-item>
          </div>
          <div v-show="selectExpended">
            <el-form-item label="商品名称" prop="merchandiseName">
              <el-input v-model.trim="queryForm.merchandiseName" placeholder="请输入商品名称" maxlength="30"
                        @keyup.enter.native="handleQuery"/>
            </el-form-item>
            <el-form-item label="生成状态" prop="generateFlag">
              <el-select v-model="queryForm.generateFlag" placeholder="请选择生成状态">
                <el-option
                  v-for="item in generateFlagType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发票类型" prop="billingType">
              <el-select v-model="queryForm.billingType" placeholder="请选择发票类型">
                <el-option
                  v-for="item in invoiceType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-show="selectExpended">
            <el-form-item label="订单类型" prop="orderType">
              <el-select v-model="queryForm.orderType" placeholder="请选择订单类型">
                <el-option
                  v-for="item in orderTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开票周期" prop="billingCycle">
              <el-date-picker
                v-model="queryForm.billingCycle"
                type="month"
                format="yyyy-MM"
                value-format="yyyy-MM"
                placeholder="选择开票周期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="匹配情况" prop="matchStatus">
              <el-select v-model="queryForm.matchStatus" placeholder="请选择">
                <el-option
                  v-for="item in matchStatusType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <select-button
          show-status
          @list-close="handleListClose"
          @list-show="handleListShow"
          @select="handleQuery"
          @reset="handleReset">
        </select-button>
      </div>
    </div>
    <div class="content-main">
      <div class="main-top">
        <div class="main-top-left">
          <div>订单列表</div>
          <el-popover placement="right" width="80" trigger="click">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
              全选
            </el-checkbox>
            <div class="part"></div>
            <el-checkbox-group v-model="colOptions" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="item in colSelect" :label="item" :key="item" style="display:block;margin-top: 6px"/>
            </el-checkbox-group>
            <div class="operate" slot="reference" style="cursor:pointer">
              <img src="@/assets/icon/set.png">
              <span>列设置</span>
            </div>
          </el-popover>
        </div>
        <div class="main-top-right">
          <el-button type="default" @click="handleBillingType">发票类型</el-button>
          <el-button type="default" @click="handleOriginalOrderRematch">重新匹配</el-button>
          <el-button type="primary" @click="handleBillingTradeGenerate">生成发票</el-button>
        </div>
      </div>
      <div class="main-middle">
        <el-table
          ref="multipleTable"
          :data="tableData"
          border
          stripe
          v-loading="recordsLoading"
          :header-cell-style="handleHeaderCellStyle"
          @selection-change="handleSelection"
          style="width: 100%"
        >
          <el-table-column type="selection" width="70" fixed="left"/>
          <el-table-column type="index" label="序号" width="60"/>
          <el-table-column v-if="colData[0].isTrue" prop="dataId" label="业务单号" width="180"/>
          <el-table-column v-if="colData[1].isTrue" prop="orderTime" label="订单时间" width="180"/>
          <el-table-column v-if="colData[2].isTrue" prop="created" label="生成时间" width="180"/>
          <el-table-column v-if="colData[3].isTrue" prop="billingCycle" label="开票周期" width="120"/>
          <el-table-column v-if="colData[4].isTrue" prop="billingType" label="发票类型" width="120"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[5].isTrue" prop="orderType" label="订单类型" width="120"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[6].isTrue" prop="orderNature" label="订单性质" width="120"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[7].isTrue" prop="buyerCode" label="购方编码" min-width="180"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[8].isTrue" prop="buyerName" label="购方名称" min-width="220"
                           show-overflow-tooltip/>
          <el-table-column v-if="colData[9].isTrue" prop="buyerAddressTel" label="购方地址电话" min-width="220"
                           show-overflow-tooltip/>
          <el-table-column v-if="colData[10].isTrue" prop="buyerBankAccount" label="购方银行账号" min-width="220"
                           show-overflow-tooltip/>
          <el-table-column v-if="colData[11].isTrue" prop="sellerName" label="销方名称" width="170"/>
          <el-table-column v-if="colData[12].isTrue" prop="middleKind" label="中类名称" width="120"/>
          <el-table-column v-if="colData[13].isTrue" prop="merchandiseCode" label="商品编码" width="250"/>
          <el-table-column v-if="colData[14].isTrue" prop="merchandiseName" label="商品名称" width="250"/>
          <el-table-column v-if="colData[15].isTrue" prop="taxClassName" label="税收分类名称" width="250"/>
          <el-table-column v-if="colData[16].isTrue" prop="taxRate" label="税率" width="120"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[17].isTrue" prop="amount" label="金额" width="153"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[18].isTrue" prop="taxAmount" label="税额" width="120"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[19].isTrue" prop="sumAmount" label="价税合计" width="153"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[20].isTrue" prop="discountAmount" label="折扣金额" width="180"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[21].isTrue" prop="discountTaxAmount" label="折扣税额" width="180"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[22].isTrue" prop="discountSumAmount" label="折扣价税合计" width="180"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[23].isTrue" prop="specification" label="规格型号" width="70"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[24].isTrue" prop="price" label="商品单价" width="120"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[25].isTrue" prop="unit" label="商品单位" width="120"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[26].isTrue" prop="quantity" label="商品数量" width="120"
                           :formatter="handleTableValueFormat"/>
          <el-table-column v-if="colData[27].isTrue" prop="matchStatus" label="匹配情况" fixed="right" width="120">
            <template slot-scope="scope">
              <div :style="{color:handleIsMatchColor(scope.row.matchStatus)}">
                <el-tooltip v-if=!getIsMatch(scope.row.matchStatus)
                            :content=scope.row.matchMessage
                            placement="bottom">
                  <span>{{ handleIsMatch(scope.row.matchStatus) }}</span>
                </el-tooltip>
                <span v-else>{{ handleIsMatch(scope.row.matchStatus) }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="colData[28].isTrue" prop="generateFlag" label="待开发票" fixed="right" width="120"
                           :formatter="handleTableValueFormat"/>
        </el-table>
        <div class="table-statistics">
          <div class="statistics-left">
            <div>已勾选<span style='color: red;'> {{selections.length}} </span>条</div>
            <div>金额：{{ '￥' + selectedAmount }}</div>
            <div>税额：{{'￥' + selectedTaxAmount }}</div>
            <div>价税合计：{{'￥'+ selectedSumAmount }}</div>
          </div>
          <div class="statistics-right">
            <div>总数量：{{ totals }}条</div>
            <div>总金额：{{ '￥'+totalsAmount }}</div>
            <div>总税额：{{ '￥' +totalsTaxAmount }}</div>
            <div>总价税合计：{{ '￥' +totalsSumAmount }}</div>
          </div>
        </div>
        <!-- 分页组件 -->
        <div class="content-page">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="pageSizes"
            :page-size="queryForm.size"
            layout="total, sizes, prev, pager, next"
            :total="dataTotal">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 提示弹窗   -->
    <el-dialog append-to-body title="提示" width="26%" :visible.sync="tipsVisible">
      <div class="tips-body">
        <span>{{tipsContent}}</span>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="tipsVisible = false">确定</el-button>
      </div>
    </el-dialog>

    <!-- 待开发票生成弹窗   -->
    <el-dialog append-to-body title="待开发票生成" width="420px" :visible.sync="billingTradeVisible">
      <div class="dialog-trade-generate">
        <div class="invoice-type" v-if="isShowInvoiceType">
          <div>发票类型</div>
          <el-select v-model="billingTradeInvoiceType" placeholder="请选择发票类型">
            <el-option
              v-for="item in invoiceType"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="order-totals">
          <div>
            <label>已选择：</label>
            <span>{{billingTradeTotals}}</span>
          </div>
          <div>
            <label>价税合计：</label>
            <span>{{billingTradeTotalsSumAmount}}</span>
          </div>
        </div>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button type="default" @click="handleBillingTradeGenerateCancel">取消</el-button>
        <el-button type="primary" @click="handleBillingTradeGenerateConfirm">确认生成</el-button>
      </div>
    </el-dialog>

    <!-- 修改发票类型弹窗   -->
    <el-dialog append-to-body title="修改发票类型" width="26%" :visible.sync="updateBillingTypeVisible">
      <div class="wait-invoice" style="margin-bottom: 30px">
          <span class="invoice-type">
            发票类型
          </span>
        <el-select v-model="queryForm.newBillingType" placeholder="请选择">
          <el-option
            v-for="item in invoiceType"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="dialog-footer" slot="footer" style="margin-right: 5px">
        <el-button type="default" @click="updateBillingTypeVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmUpdateBillingType">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  generateBillingTrade,
  getBillingTradeStatistics,
  getOriginalOrderListByPage,
  getOriginalOrderStatistics,
  refresh,
  updateBillingType
} from '@/service/original-order.js'
import { ORIGINAL_ORDER_BILLING_TYPES } from '@/content/invoice.js'
import { changeMethods, commonsMethods, dateOption, tableStyle } from '@/util/mixins'

export default {
  name: 'RetailOriginalOrder',
  mixins: [tableStyle, dateOption, changeMethods, commonsMethods],
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      tableData: [],
      selections: [],
      /* 统计数据区 */
      totals: 0,
      totalsAmount: 0,
      totalsTaxAmount: 0,
      totalsSumAmount: 0,
      /* 待开生成数据区 */
      billingTradeVisible: false,
      isShowInvoiceType: false,
      billingTradeInvoiceType: 'Zzzp',
      billingTradeTotals: 0,
      billingTradeTotalsSumAmount: 0,
      ignoreAmount: 0,
      ignoreCreateWaitInvoiceContent: '',
      ignoreNum: '',
      updateBillingTypeVisible: false,
      tipsVisible: false,
      tipsContent: '',
      changeNumTips: false,
      changeNumTipsContent: '',
      colData: [
        { title: '业务单号', isTrue: true },
        { title: '订单时间', isTrue: true },
        { title: '生成时间', isTrue: true },
        { title: '开票周期', isTrue: true },
        { title: '发票类型', isTrue: true },
        { title: '订单类型', isTrue: true },
        { title: '订单性质', isTrue: true },
        { title: '购方编号', isTrue: true },
        { title: '购方名称', isTrue: true },
        { title: '购方地址电话', isTrue: true },
        { title: '购方银行账号', isTrue: true },
        { title: '销方名称', isTrue: true },
        { title: '中类名称', isTrue: true },
        { title: '商品编码', isTrue: true },
        { title: '商品名称', isTrue: true },
        { title: '税收分类名称', isTrue: true },
        { title: '税率', isTrue: true },
        { title: '金额', isTrue: true },
        { title: '税额', isTrue: true },
        { title: '价税合计', isTrue: true },
        { title: '折扣金额', isTrue: true },
        { title: '折扣税额', isTrue: true },
        { title: '折扣价税合计', isTrue: true },
        { title: '规格型号', isTrue: true },
        { title: '商品单价', isTrue: true },
        { title: '商品单位', isTrue: true },
        { title: '商品数量', isTrue: true },
        { title: '匹配情况', isTrue: true },
        { title: '待开发票', isTrue: true }
      ],
      colOptions: [],
      colSelect: [],
      isIndeterminate: true,
      checkAll: false,
      recordsLoading: false,
      selectExpended: false,
      button: '',
      queryForm: {
        ids: [],
        sellerOrgId: '',
        dataId: '',
        uniqueCode: '',
        buyerName: '',
        sellerName: '',
        billingType: '',
        newBillingType: '',
        createdRange: [],
        orderTimeRange: [],
        matchStatus: '',
        generateFlag: 0,
        merchandiseName: '',
        orderType: '',
        billingCycle: '',
        waitBilling: '',
        page: 1,
        size: 15
      },
      invoiceType: ORIGINAL_ORDER_BILLING_TYPES,
      orderTypes: [
        {
          'value': '',
          'label': '全部',
        },
        {
          'value': 'CY',
          'label': '差异订单',
        },
        {
          'value': 'ZC',
          'label': '正常订单',
        }
      ],
      orderNatures: [
        {
          'value': '',
          'label': '全部',
        },
        {
          'value': 'ZKH',
          'label': '折扣',
        },
        {
          'value': 'ZPH',
          'label': '赠品',
        },
        {
          'value': 'ZCH',
          'label': '正常',
        }
      ],
      matchStatusType: [
        {
          'value': '',
          'label': '全部',
        },
        {
          'value': 'Y',
          'label': '已匹配',
        },
        {
          'value': 'N',
          'label': '未匹配',
        }
      ],
      generateFlagType: [
        {
          'value': '',
          'label': '全部',
        },
        {
          'value': 0,
          'label': '未生成',
        },
        {
          'value': 1,
          'label': '已生成',
        },
      ],
      changeNum: '',
      dataTotal: 0,
      pageSizes: [15, 30, 50, 100]
    }
  },
  watch: {
    '$store.state.invoiceIssue.CurrentOrganization' (value) {
      this.queryForm.sellerOrgId = value
      this.handleQuery()
    },
    colOptions (valArr) {
      const arr = this.colSelect.filter(i => valArr.indexOf(i) < 0) // 未选中
      this.colData.filter(i => {
        if (arr.indexOf(i.title) != -1) {
          i.isTrue = false
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
        } else {
          i.isTrue = true
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
        }
      })
      localStorage.setItem('originalOrderLineSet', JSON.stringify(this.colData))
    }
  },
  computed: {
    selectedAmount () {
      let amount = this.selections.map(i => i.amount).reduce((num, sum) => Number(num) + Number(sum), 0)
      return amount.toFixed(2)
    },
    selectedTaxAmount () {
      let taxAmount = this.selections.map(i => i.taxAmount).reduce((num, sum) => Number(num) + Number(sum), 0)
      return taxAmount.toFixed(2)
    },
    selectedSumAmount () {
      let sumAmount = this.selections.map(i => i.sumAmount).reduce((num, sum) => Number(num) + Number(sum), 0)
      return sumAmount.toFixed(2)
    }
  },
  created () {
    this.queryForm.sellerOrgId = this.model.orgId
    this.colSelect = this.colData.map(i => i.title)
    this.handleQuery()
    this.handleGetLineSet()
    this.handleInitDictionaries()
  },
  methods: {
    /* 原始订单数据查询 */
    handleQuery () {
      this.handleGetOriginalOrderList()
      this.handleOriginalOrderStatistics()
    },
    /* 原始订单发票列表获取 */
    async handleGetOriginalOrderList () {
      this.recordsLoading = true
      const { success, data } = await getOriginalOrderListByPage(this.queryForm)
      this.recordsLoading = false
      if (success) {
        this.dataTotal = data.total
        this.tableData = data.list
      }
    },
    /* 原始订单数据统计 */
    async handleOriginalOrderStatistics () {
      const { success, data } = await getOriginalOrderStatistics(this.queryForm)
      if (success) {
        this.totals = data.total
        this.totalsAmount = data.amount == null ? 0 : data.amount
        this.totalsTaxAmount = data.taxAmount == null ? 0 : data.taxAmount
        this.totalsSumAmount = data.sumAmount == null ? 0 : data.sumAmount
      }
    },
    // 批量修改发票类型
    handleBillingType () {
      this.queryForm.newBillingType = ''
      if (this.selections.length > 0) {
        //勾选
        this.queryForm.ids = this.selections.map(i => i.id)
        if (this.selections.filter(item => item.generateFlag == 1).length > 0) {
          this.toast('已生成待开发票的订单,发票类型不可修改', 'warning')
          return
        }
      }
      this.updateBillingTypeVisible = true
    },
    // 确认修改发票类型
    confirmUpdateBillingType () {
      //调用修改接口
      this.handleUpdateBillingType()
      this.updateBillingTypeVisible = false
    },
    // 修改发票类型
    async handleUpdateBillingType () {
      this.recordsLoading = true
      if (this.queryForm.generateFlag == 1) {
        this.toast('已生成待开发票的订单,发票类型不可修改', 'warning')
        return
      }
      const { success, message } = await updateBillingType(this.queryForm)
      this.recordsLoading = false
      if (success) {
        this.queryForm.newBillingType = ''
        this.queryForm.ids = []
        this.handleQuery()
        this.toast('修改发票类型成功！', 'success')
      } else {
        this.toast(message, 'warning')
        this.recordsLoading = false
      }
    },
    /* 重新匹配 */
    handleOriginalOrderRematch () {
      // 勾选
      if (this.selections.length > 0) {
        if (this.selections.filter(i => i.generateFlag == 1).length > 0) {
          this.toast('重新匹配失败，数据中包含已经生成待开发票的订单！', 'warning')
          return
        }
        if (this.selections.length === this.selections.filter(i => i.generateFlag == 1).length && this.selections.length === this.selections.filter(i => i.matchStatus == 'MATCH').length) {
          this.toast('重新匹配失败，尚无满足条件的原始订单！', 'warning')
          return
        }
        this.queryForm.ids = this.selections.map(i => i.id)
      } else {
        if (this.queryForm.generateFlag == 1 && this.queryForm.matchStatus == 'Y') {
          this.toast('重新匹配失败，尚无满足条件的原始订单！', 'warning')
          return
        }
        //按条件
        if (this.queryForm.generateFlag == 1) {
          this.toast('重新匹配失败，数据中包含已经生成待开发票的订单！', 'warning')
          return
        }
      }
      //调用重新匹配接口
      this.recordsLoading = true
      refresh(this.queryForm).then(res => {
        if (res.success) {
          this.queryForm.ids = []
          this.handleQuery()
          this.toast('执行重新匹配成功', 'success')
        }
        this.recordsLoading = false
      }).catch(err => {
        this.recordsLoading = false
        reject(err)
      })
    },
    /* 待开发票生成 */
    async handleBillingTradeGenerate () {
      if (this.tableData == 0) {
        this.toast('待开发票生成失败，尚无满足条件的原始订单！', 'warning')
        return
      }
      const orderNatures = [...new Set(this.selections.map(i => i.orderNature))]
      if (orderNatures.length == 1 && orderNatures.includes('ZPH')) {
        this.tipsVisible = true
        this.tipsContent = '您勾选的数据中存在缺少被赠品行数据，请重新勾选您要生成待开发票的数据！'
        return
      }
      this.queryForm.ids = this.selections.map(i => i.id)
      const { success, data } = await getBillingTradeStatistics(this.queryForm)
      if (success) {
        this.billingTradeTotals = data.total
        this.billingTradeTotalsSumAmount = data.sumAmount
        this.billingTradeVisible = true
      }
    },
    /* 待开发票生成确认 */
    async handleBillingTradeGenerateConfirm () {
      const { success } = await generateBillingTrade(this.queryForm)
      if (success) {
        this.queryForm.ids = []
        this.handleQuery()
        this.billingTradeVisible = false
        this.toast('待开发票生成成功！', 'success')
      }
    },
    /* 待开发票生成取消 */
    handleBillingTradeGenerateCancel () {
      this.billingTradeVisible = false
    },
    /* 获取列设置 */
    handleGetLineSet () {
      if (JSON.parse(localStorage.getItem('originalOrderLineSet')) != null) {
        if (JSON.parse(localStorage.getItem('originalOrderLineSet')).length < this.colData.length) {
          localStorage.removeItem('originalOrderLineSet')
          this.colOptions = this.colData.map(item => item.title)//默认所有
          this.checkAll = true
          this.isIndeterminate = false
          return
        }
        this.colData = JSON.parse(localStorage.getItem('originalOrderLineSet'))
        if (this.colData.filter(i => i.isTrue == true).length === this.colSelect.length) {
          this.checkAll = true
          this.isIndeterminate = false
        }
        this.colOptions = JSON.parse(localStorage.getItem('originalOrderLineSet')).filter(i => i.isTrue == true).map(item => item.title) //选中
      } else {
        this.colOptions = this.colData.map(item => item.title)//默认所有
        this.checkAll = true
        this.isIndeterminate = false
      }
    },
    handleCheckAllChange (val) {
      this.colOptions = val ? this.colSelect : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange (value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.colSelect.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.colSelect.length
    },
    /* 匹配情况颜色 */
    handleIsMatchColor (status) {
      switch (status) {
        case 'N':
          return '#F5222D'
        default:
          return '#666666'
      }
    },
    /* 初始化字典值 */
    handleInitDictionaries () {
      this.getDicts('open_bill_type').then(({ data }) => this.openBillType = data)
      // 订单性质
      // this.getDicts('order_nature').then(({ data }) => this.orderNatures = [{
      //   label: '全部',
      //   value: ''
      // }, ...data])
      // 订单类型
      // this.getDicts('order_type').then(({ data }) => this.orderTypes = [{
      //   label: '全部',
      //   value: ''
      // }, ...data])
      // 匹配情况
      // this.getDicts('match_status').then(({ data }) => this.matchStatusType = [{
      //   label: '全部',
      //   value: ''
      // }, ...data])
      // 是否生成待开
      // this.getDicts('create_wait_billing').then(({ data }) => this.generateFlagType = [{
      //   label: '全部',
      //   value: ''
      // }, ...data])
    },
    // 收起筛选列表
    handleListClose () {
      this.selectExpended = false
    },
    // 展开筛选列表
    handleListShow () {
      this.selectExpended = true
    },
    // 重置筛选列表
    handleReset () {
      this.queryForm = {
        ids: [],
        buyerName: '',
        sellerName: '',
        dataId: '',
        uniqueCode: '',
        billingType: '',
        orderTimeRange: [],
        matchStatus: '',
        sellerOrgId: '',
        generateFlag: '',
        merchandiseName: '',
        waitBilling: '',
        page: 1,
        size: 15
      }
    },
    // 表格值格式化
    handleTableValueFormat (row, column) {
      let value = row[column.property]
      switch (column.label) {
        case '开票周期':
        case '购买方编码':
        case '中类名称':
        case '规格型号':
        case '商品单位':
        case '商品数量':
          if (value == null || value == '') {
            return '--'
          }
          return value
        case '发票类型':
          return this.handleValueToLabel('BillingType', value)
        case '订单类型':
          if (value != null && value != '') {
            return this.getDictLabel(this.orderTypes, value)
          }
          return '--'
        case '订单性质':
          if (value != null && value != '') {
            return this.getDictLabel(this.orderNatures, value)
          }
          return '--'
        case '商品单价':
        case '金额':
        case '税额':
        case '价税合计':
        case '折扣金额':
        case '折扣税额':
        case '折扣价税合计':
          if (value == null) return '--'
          return '￥' + value
        case '税率':
          if (value == '免税' || value == '不征税') return value
          return value * 100 + '%'
        case '匹配情况':
          if (value != null && value != '') {
            return this.getDictLabel(this.matchStatusType, value)
          }
          return '--'
        case '待开发票':
          if (value != null && value != '') {
            return this.getDictLabel(this.generateFlagType, value)
          }
          return '--'
        default:
          return value
      }
    },
    getIsMatch (value) {
      return value == 'Y'
    },
    handleIsMatch (value) {
      if (value != null && value != '') {
        return this.getDictLabel(this.matchStatusType, value)
      }
      return '--'
    },
    // 切换分页条数
    handleSizeChange (value) {
      this.queryForm.size = value
      this.handleQuery()
    },
    // 翻页
    handleCurrentChange (value) {
      this.queryForm.page = value
      this.cancelDeleteBatch()
      this.handleQuery()
    },
    // 记住选中
    handleGetRowKey (row) {
      return row.id
    },
    // 取消选中
    cancelDeleteBatch () {
      this.selections = []
    },
    /* 表格数据选中 */
    handleSelection (value) {
      this.selections = value
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/style/select.scss";

  .content-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100% - 180px);

    .main-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 12px;

      .main-top-left {
        display: flex;

        div {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
          line-height: 22px;
        }

        .operate {
          display: flex;
          align-items: center;
          margin-left: 30px;

          img {
            width: 20px;
            height: 20px;
            margin-right: 1px;
          }

          span {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1890FF;
            line-height: 22px;
          }
        }

      }
    }

    .main-middle {
      .table-statistics {
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #EBF4FF;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;

        .statistics-left {
          display: flex;
          justify-content: space-between;

          div {
            margin-right: 20px;
          }

          div:first-child {
            margin-left: 10px;
          }
        }

        .statistics-right {
          display: flex;
          justify-content: space-between;

          div {
            margin-left: 20px;
          }
          div:last-child {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .dialog-content {
    margin-top: -6px;
    margin-left: 24px;
    margin-right: 24px;
    text-align: left;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
  }

  .dialog-body {
    margin: -16px 24px 0 24px;

    .body-top {
      .top-first-row {
        display: flex;
        margin-bottom: 12px;
      }

      .top-second-row {
        display: flex;
        text-align: left;
        margin-bottom: 24px;

        span {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 20em;
        }
      }

      label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }

      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
    }

    .body-bottom {
      text-align: left;
      border-top: 1px solid #E9E9E9;
      margin: 0 -24px;
      padding: 24px;

      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F5222D;
        line-height: 20px;
      }
    }
  }

  .limit-dialog {
    margin-top: -20px;
    padding-left: 20px;
    text-align: left;
    font-size: 14px;

    .data {
      padding-bottom: 25px;
      border-bottom: 1px solid #E9E9E9;

      span {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
      }
    }

    .message {
      padding: 24px 0;
      color: #F5222D;
    }
  }

  .el-dialog__footer {
    padding: 10px 24px 24px;
    text-align: right;
    box-sizing: border-box;
  }

  .total-data {
    margin-left: 20px;
    font-weight: bold;
  }

  .amount {
    margin-left: 20px;
    font-weight: bold;
  }

  .dialog-trade-generate {
    display: flex;
    flex-direction: column;
    padding: 0 24px;

    .invoice-type {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      div {
        margin-right: 8px;
      }
    }

    .order-totals {
      display: flex;
      margin-bottom: 12px;

      div {
        margin-right: 49px;
      }
    }

    label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    span {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
    }
  }

  .wait-invoice {
    text-align: left;
    margin-left: 20px;
    font-size: 16px;

    .summation {
      font-size: 23px;
      margin-top: 11px;
      margin-bottom: 20px;
    }
  }

  .wait-invoice-tips {
    margin-top: 38px;
  }

  .wait-invoice-button {
    margin-top: 33px;
    margin-bottom: 14px;
  }

  .update-billing-type-button {
    text-align: center;
    margin-top: 33px;
    margin-bottom: 24px;
  }

  .unMatch {
    font-weight: bolder;
    padding: 0 22px
  }

  .button-footer {
    text-align: center;
    margin-top: 25px;
  }

  .invoice-type {
    margin-right: 5px;
    font-size: 14px;
  }

  .tips-body {
    margin: 0 24px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    text-align: left;
  }
</style>

