<template>
  <div v-if="!uploadFalse" class="dialog-wrap">
    <div class="title">导入开票</div>
    <div class="header">
      <span>文件类型：</span>
      <el-select v-model="fileType">
        <el-option value="excel" label="Excel"/>
        <el-option value="tsv" label="tsv文件"/>
      </el-select>
    </div>
    <div class="header">
      <span>自动拆分：</span>
      <el-select v-model="autoSplit">
        <el-option value="false" label="否"/>
        <el-option value="true" label="是"/>
      </el-select>
    </div>
    <div class="content">
      <ul v-if="fileType == 'excel'">
        <li>1.下载批量开票模版；</li>
        <li>2.打开下载的模版，填入发票信息，上限1000条；</li>
        <li>3.将编辑好的模版导入系统；</li>
        <li>4.点击"开具/批量开票"即可提交开票请请求。</li>
      </ul>
      <div v-else class="header">
        <span>开票类型：</span>
        <el-select v-model="billingType">
          <el-option value="c" label="增值税普通发票"/>
          <el-option value="p" label="增值税电子普票"/>
          <el-option value="s" label="增值税专用发票"/>
        </el-select>
      </div>
    </div>
    <div>
      <el-upload accept=".xlsx,.tsv" action="#" :multiple="false" :show-file-list="false"
                 :on-exceed="handleExceed"
                 :on-change="uploadFile">
        <el-input v-model="fileName" placeholder="文件名称..." style="width:254px"></el-input>
        <el-button type="default" icon="el-icon-upload2">选择文件</el-button>
      </el-upload>
    </div>
    <div v-if="fileType =='excel'" class="tip">
      <span>注：商品前的订单编号一致时，默认开在同一张发票上。</span>
    </div>
    <div class="dialog-footer">
      <el-button type="default" @click="handleClose">取消</el-button>
      <el-button type="primary" :disabled="btnDisable" @click="handleSave">导入</el-button>
    </div>
  </div>
  <div v-else class="dialog-wrap">
    <div class="title">导入结果</div>
    <ul>
      <li>导入失败！</li>
      <li style="color: #F5222D"><span>{{result.failSize}}</span>条数据不符合导入规则，请检查修改后重新导入</li>
    </ul>
    <div class="dialog-footer">
      <el-button type="default" @click="handleDownload">下载错误列表</el-button>
      <el-button type="primary" @click="handleUploadAgain">再次导入</el-button>
    </div>
  </div>
</template>

<script>
import { invoiceUploadBatch } from '@/service/invoice-open'

export default {
  name: 'InvoiceUploadBatch',
  data () {
    return {
      uploadFalse: false,
      fileName: '',
      fileType: 'excel',
      autoSplit: 'false',
      billingType: '',
      file: null,
      result: {},
      btnDisable: false
    }
  },
  methods: {
    // 上传错误捕获
    handleExceed () {
      this.toast('上传文件出错', 'error')
    },
    // 文件上传成功
    uploadFile (file) {
      this.fileName = file.name
      this.file = file.raw
    },
    // 点击导入
    async handleSave () {
      this.btnDisable = true
      if (!this.file) {
        this.toast('请先选择文件', 'warning')
        this.btnDisable = false
        return
      }
      const index = this.fileName.match(/\./).index + 1
      const fileType = this.fileName.substring(index, this.fileName.length)
      // tsv文件校验
      if (this.fileType === 'tsv') {
        if (fileType !== 'tsv') {
          this.toast('文件格式错误，请选择tsv文件', 'error')
          this.btnDisable = false
          return
        }
        if (this.billingType === '') {
          this.toast('开具类型不可为空', 'error')
          this.btnDisable = false
          return
        }
      }
      let formData = new FormData()
      formData.append('file', this.file)
      formData.append('fileName', this.fileName)
      formData.append('fileType', this.fileType)
      formData.append('autoSplit', this.autoSplit)
      formData.append('billingType', this.billingType)
      const { success, data } = await invoiceUploadBatch(formData)
      if (success) {
        if (data.success) {
          this.toast('批量导入成功！', 'success')
          this.handleClose()
        } else {
          this.uploadFalse = true
          this.result = data
        }
      }
      this.btnDisable = false
    },
    // 点击取消
    handleClose () {
      this.$emit('close')
    },
    // 下载
    handleDownload () {
      let prefix = ''
      let origin = ''
      let resultUrl = this.result.url.replace(origin, prefix)
      const a = document.createElement('a')
      a.setAttribute('download', '')
      a.setAttribute('href', resultUrl)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    // 点击再次导入
    handleUploadAgain () {
      this.file = null
      this.fileName = ''
      this.result.error = 0
      this.result.success = 0
      this.uploadFalse = false
    }
  }
}
</script>

<style scoped lang="scss">
  .dialog-wrap {
    color: #333333;
    font-size: 14px;
    margin-top: -36px;

    .title {
      font-size: 18px;
      text-align: left;
      padding-left: 24px;
      margin-bottom: 24px;
    }

    .header {
      text-align: left;
      padding-left: 24px;
      padding-bottom: 24px;

      span {
        margin-right: 8px;
      }
    }

    .el-button {
      padding: 0;
      width: 106px;
      margin-left: 10px;
      text-align: center;
    }

    .tip {
      color: #F5222D;
      padding: 24px 0;
      margin-top: 24px;
      text-align: center;
      border-top: 1px solid #E9E9E9;
    }

    .dialog-footer {
      text-align: right;
      padding: 24px 24px;
    }
  }

  ul {
    margin-top: 0;
    list-style: none;
    text-align: left;

    li {
      padding-bottom: 12px;
    }
  }
</style>
