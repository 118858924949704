<template>
  <el-dialog append-to-body
             :visible.sync="importTemplate"
             width="450px">
    <div class="dialog-wrap">
      <div class="title">导入全电发票</div>
      <el-upload accept=".xlsx" action="#" :multiple="false" :show-file-list="false"
                 :on-exceed="handleExceed"
                 :on-change="uploadFile">
        <el-input v-model="fileName" placeholder="文件名称..." style="width:254px"></el-input>
        <el-button type="default" icon="el-icon-upload2">选择文件</el-button>
      </el-upload>
      <div class="dialog-footer">
        <el-button type="default" @click="handleClose">取消</el-button>
        <el-button type="primary" :disabled="btnDisable" @click="handleSave">导入</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { importElectronInvoice  } from '@/service/electron-invoice'

export default {
  name: 'importTemplate',
  data() {
    return {
      uploadFalse: false,
      fileName: '',
      fileType: 'excel',
      autoSplit: 'false',
      billingType: '',
      file: null,
      result: {},
      btnDisable: false,
      importTemplate: false
    }
  },
  methods: {
    open () {
      this.importTemplate = true
    },
    handleClose () {
      this.importTemplate = false
    },
    // 上传错误捕获
    handleExceed() {
      this.toast('上传文件出错', 'error')
    },
    // 文件上传成功
    uploadFile(file) {
      this.fileName = file.name
      this.file = file.raw
    },
    // 点击导入
    async handleSave() {
      this.btnDisable = true
      if (!this.file) {
        this.toast('请先选择文件', 'warning')
        this.btnDisable = false
        return
      }
      let formData = new FormData()
      formData.append('file', this.file)
      const {success, data} = await importElectronInvoice(formData)
      if (success) {
        if (data) {
          this.toast('全电发票更新成功！', 'success')
          this.handleClose()
        } else {
          this.uploadFalse = true
          this.result = data
        }
      }
      this.btnDisable = false
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-wrap {
  color: #333333;
  font-size: 14px;
  margin-top: -36px;

  .title {
    font-size: 18px;
    text-align: left;
    padding-left: 24px;
    margin-bottom: 24px;
  }

  .header {
    text-align: left;
    padding-left: 24px;
    padding-bottom: 24px;

    span {
      margin-right: 8px;
    }
  }

  .el-button {
    padding: 0;
    width: 106px;
    margin-left: 10px;
    text-align: center;
  }

  .tip {
    color: #F5222D;
    padding: 24px 0;
    margin-top: 24px;
    text-align: center;
    border-top: 1px solid #E9E9E9;
  }

  .dialog-footer {
    text-align: right;
    padding: 24px 24px;
  }
}

ul {
  margin-top: 0;
  list-style: none;
  text-align: left;

  li {
    padding-bottom: 12px;
  }
}
</style>
