<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form class="select-form" ref="queryForm" :model="queryForm" label-width="100px">
          <div>
            <el-form-item label="生成时间">
              <el-date-picker
                v-model="createdRange"
                type="daterange"
                range-separator
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="购方名称" prop="buyerName">
              <el-input
                v-model.trim="queryForm.buyerName"
                placeholder="请输入购买方名称"
                maxlength="30"
                @keyup.enter.native="handleGetBillingTradeList()"
              />
            </el-form-item>
            <el-form-item label="业务单号" prop="dataId">
              <el-input
                v-model.trim="queryForm.dataId"
                placeholder="请输入业务单号"
                maxlength="30"
                @keyup.enter.native="handleGetBillingTradeList()"
              />
            </el-form-item>
          </div>
          <div v-show="selectExpended">
            <el-form-item label="发票类型" prop="billingType">
              <el-select v-model="queryForm.billingType" placeholder="请选择">
                <el-option
                  v-for="item in invoiceType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数据来源" prop="source">
              <el-select v-model="queryForm.source" placeholder="请选择">
                <el-option
                  v-for="item in invoiceSource"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数电发票号码">
                <el-input v-model.trim="queryForm.eInvoiceNo" placeholder="请输入数电发票号码"  maxlength="20"/>
            </el-form-item>
          </div>
        </el-form>
        <div class="select-button">
          <select-button
            show-status
            @list-close="handleListClose"
            @list-show="handleListShow"
            @select="handleQuery"
            @reset="handleReset"
          ></select-button>
        </div>
      </div>
    </div>
    <div class="content-main">
      <div>
        <div class="main-top">
          <span>票据列表</span>
          <div>
            <el-button type="primary" @click="exportTemplate" style="width:120px;">导出全电发票</el-button>
            <el-button type="primary" @click="importTemplate" style="width:120px;">导入全电发票</el-button>
            <el-button type="default" @click="handleTemplate">下载模板</el-button>
            <el-button type="default" @click="handleMergeInvoice">合并发票</el-button>
            <el-button type="primary" @click="handleBatchBill">批量开票</el-button>
            <el-button type="primary" @click="handleImport">导入开票</el-button>
            <el-button type="default" @click="handleBatchDelete">批量删除</el-button>
          </div>
        </div>
        <div class="content-table">
          <el-table
            border
            stripe
            :data="tableData"
            ref="retailTable"
            v-loading="recordsLoading"
            :row-key="handleGetRowKey"
            @row-click="handleOpenDetails"
            :header-cell-style="handleHeaderCellStyle"
            @selection-change="handleSelectionChange"
            style="width: 100%"
          >
            <el-table-column type="selection" width="50" :reserve-selection="true" fixed="left"/>
            <el-table-column type="index" label="序号" width="60" fixed="left" class-name="dl-pointer"/>
            <el-table-column prop="created" label="生成时间" width="180"/>
            <el-table-column prop="buyerName" label="购买方名称" min-width="220" show-overflow-tooltip/>
            <el-table-column prop="buyerTaxNo" label="购买方税号" width="170"/>
             <el-table-column prop="eInvoiceNo" label="数电发票号码" min-width="120" />

            <el-table-column prop="invoiceCode" label="发票代码" width="170"/>
            <el-table-column prop="invoiceNo" label="发票号码" width="170"/>
            <el-table-column prop="invoiceTime" label="开票日期" width="170"/>
            <el-table-column prop="amount" label="金额" width="120" :formatter="handleTableValueFormat"/>
            <el-table-column prop="taxAmount" label="税额" width="120" :formatter="handleTableValueFormat"/>
            <el-table-column prop="sumAmount" label="价税合计" width="120" :formatter="handleTableValueFormat"/>
            <el-table-column prop="billingType" label="发票类型" width="110" :formatter="handleTableValueFormat"/>
            <el-table-column prop="buyerAddressTel" label="购买方地址电话" width="220" show-overflow-tooltip/>
            <el-table-column prop="buyerBankAccount" label="购买方银行账号" width="220" show-overflow-tooltip/>
            <el-table-column prop="billingFrom" label="业务类型" width="110" :formatter="handleTableValueFormat"/>
            <!-- <el-table-column prop="openBillType" label="单据类型" width="110" :formatter="handleTableValueFormat"/> -->
            <el-table-column prop="source" label="数据来源" width="110" :formatter="handleTableValueFormat"/>
            <el-table-column prop="dataId" label="业务单号" width="250"/>
            <el-table-column prop="operate" label="操作" fixed="right" width="135">
              <template slot-scope="scope">
                <el-button type="text" v-if="scope.row.invoiceStatus != 'YKJ'" @click="handleCheckInvoiceLimit(scope.row)">开具</el-button>
                <el-button type="text" v-if="scope.row.invoiceStatus != 'YKJ'" @click="handleInvoiceDelete(scope.row)">删除</el-button>
                <el-button type="text" v-else @click="handleDetailInvoice(scope.row)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="table-statistics">
            <div class="statistics-left">
              <div>
                已勾选
                <span style="color: red;">{{ mergeBillData.length }}</span>条
              </div>
              <div>金额：￥{{ selectedAmount }}</div>
              <div>税额：￥{{ selectedTaxAmount }}</div>
              <div>价税合计：￥{{ selectedSumAmount }}</div>
            </div>
            <div class="statistics-right">
              <div>总数量：{{ summary.total }}条</div>
              <div>总金额：￥{{ summary.amount }}</div>
              <div>总税额：￥{{ summary.taxAmount }}</div>
              <div>总价税合计：￥{{ summary.sumAmount }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页组件 -->
      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryForm.page"
          :page-sizes="pageSizes"
          :page-size="queryForm.size"
          layout="total, sizes, prev, pager, next"
          :total="dataTotal"
        ></el-pagination>
      </div>
    </div>
    <!-- 详情弹窗 -->
    <invoice-open-detail :show.sync="drawer" :invoice="invoice"/>
    <!-- 发票拆分 -->
    <invoice-split ref="invoiceSplit" :invoiceId="invoiceId" :billingFrom="billingFrom" @success="handleInvoiceSplitSuccess"/>
    <invoice-merge ref="invoiceMerge" :invoices="mergeBillData" @success="handleInvoiceSplitSuccess"/>
    <!-- 发票开具 -->
    <invoice-bill
      v-if="issueVisible"
      :show.sync="issueVisible"
      :invoice="issueData"
      @invoice-close="handleQuery"
      @billing-invoice="handleBillingInvoice"
    ></invoice-bill>
    <!-- 提示弹窗 -->
    <el-dialog append-to-body title="提示" :visible.sync="titleVisible" width="30%">
      <div class="dialog-content">{{ titleContent }}</div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="titleVisible = false">确定</el-button>
      </div>
    </el-dialog>
    <!-- 超限额提示弹窗 -->
    <el-dialog append-to-body title="超限额提醒" :visible.sync="limitVisible" width="510px">
      <div class="limit-dialog">
        <div class="data">
          当前设备开具{{ fmtBillingType(limit.billingType) }}限额：
          <span>{{ limit.limit }}</span>
        </div>
        <div class="message">当前待开发票价税合计超过税控设备开票限额，您可将开票信息进行拆分。</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleManualSplit">手动拆分</el-button>
        <el-button type="primary" @click="handleAutomaticSplit">自动拆分</el-button>
      </div>
    </el-dialog>
    <!-- 删除发票 -->
    <invoice-delete ref="invoiceDelete" :invoices="deleteInvoices" @success="handleDeleteSuccess"/>
    <!-- 更新抬头 -->
    <update-buyer :show.sync="invoiceTitleVisible" :invoice-title="invoiceTitle" @success="handleQuery"/>
    <el-dialog append-to-body
               :title="billingReturnInfo"
               :visible.sync="billingReturnInfoVisible"
               @close="closeBillingReturnInfo"
               width="410px"
    >
      <invoice-billing-return-info
        :printInfo="printInfo"
        :billingInfo="billingInfo"
        :buttonShow="buttonShow"
        @closeBillingReturnInfo="closeBillingReturnInfo"
        @navigateToAlready="navigateToAlready"
        :show.sync="issueVisible"
      ></invoice-billing-return-info>
    </el-dialog>
    <!-- 发票导入 -->
    <el-dialog append-to-body :visible.sync="uploadVisible" width="428px" :show-close="false">
      <invoice-upload-batch v-if="uploadVisible" @close="handleCloseUploadDialog"></invoice-upload-batch>
    </el-dialog>
    <!-- 批量开票 -->
    <el-dialog append-to-body :visible.sync="billBatchVisible" title="批量开票信息核对" width="358px" :show-close="false">
      <invoice-bill-batch
        v-if="billBatchVisible"
        :invoices="mergeBillData"
        @close="handleCloseBillDialog"
        @success="handleBatchBillSuccess"
      ></invoice-bill-batch>
    </el-dialog>
    <!-- 模版下载 -->
    <invoice-tmpl ref="invoiceTmpl"/>
    <!--  导出数据  -->
    <export-template ref="exportTemplate"/>
    <!--  导入发票  -->
    <import-template ref="importTemplate"/>
  </div>
</template>

<script>
import {
  waitList,
  billInvoice,
  waitSummary,
  getInventory,
  checkInvoiceLimit,
  splitInvoiceLimit,
  selectInvoice
} from '@/service/sal/invoice'
import {getEquipmentByOrgId} from '@/service/invoice-open'
import { exportElectronInvoice } from '@/service/electron-invoice'
import { OPEN_INVOICE_TYPES, BILLING_TRADE_SOURCE } from '@/content/invoice.js'
import { tableStyle, dateOption, changeMethods, commonsMethods } from '@/util/mixins'

import InvoiceTmpl from '@/views/invoice-open/components/invoice-tmpl'
import InvoiceBill from '@/views/invoice-open/components/invoice-bill'
import UpdateBuyer from '@/views/invoice-open/components/update-buyer'
import InvoiceSplit from '@/views/invoice-open/components/invoice-split'
import InvoiceMerge from '@/views/invoice-open/components/invoice-merge'
import InvoiceDelete from '@/views/invoice-open/components/invoice-delete'
import InvoiceUploadBatch from './components/InvoiceUploadBatch'
import ExportTemplate from './components/export-template'
import ImportTemplate from './components/import-template'
import InvoiceBillBatch from './components/InvoiceBillBatch'
import InvoiceBillingReturnInfo from '@/views/invoice-open/invoice-issue/components/InvoiceBillingReturnInfo'
import InvoiceOpenDetail from '@/components/invoice/InvoiceOpenDetail'

export default {
  name: 'RetailWaitInvoice',
  mixins: [tableStyle, dateOption, changeMethods, commonsMethods],
  components: {
    InvoiceTmpl,
    InvoiceBill,
    UpdateBuyer,
    InvoiceSplit,
    InvoiceMerge,
    InvoiceDelete,
    InvoiceBillingReturnInfo,
    InvoiceUploadBatch,
    InvoiceBillBatch,
    ExportTemplate,
    ImportTemplate,
    InvoiceOpenDetail
  },
  props: ['ids'],
  data () {
    return {
      queryForm: {
        buyerName: undefined,
        dataId: undefined,
        billingType: undefined,
        orgId: undefined,
        source: undefined,
        page: 1,
        size: 15,
        eInvoiceNo:''
      },
      createdRange: [],
      dataTotal: 0,
      pageSizes: [15, 30, 50, 100],
      invoiceType: OPEN_INVOICE_TYPES,
      invoiceSource: BILLING_TRADE_SOURCE,
      tableData: [],
      isSplit: true,
      invoiceId: 0,
      billingFrom: '',
      // 合并单据数据区
      mergeBillData: [],
      //开具数据区
      issueVisible: false,
      issueData: {},
      billingChannel: '',
      issueDetailVisible: false,
      titleVisible: false,
      checkVisible: false,
      limitVisible: false,
      uploadVisible: false,  // 批量导入发票弹窗
      billBatchVisible: false, // 批量开票弹窗
      limit: {},
      titleContent: '',
      forceUnlock: false,
      forceUnlockData: {},
      recordsLoading: false,
      invoiceTitleVisible: false,
      invoiceTitle: {},
      deleteInvoices: [],
      selectExpended: false,
      openBillType: [],
      billingReturnInfo: '',
      billingReturnInfoVisible: false,
      printInfo: '',
      billingInfo: '',
      buttonShow: false,
      summary: {
        total: 0,
        amount: 0,
        taxAmount: 0,
        sumAmount: 0,
      },
      deleteCheckVisible: false,
      deleteCheckVisibleSplit: false,
      //详情数据区
      drawer: false,
      invoice: {}
    }
  },
  watch: {
    // TODO 重构下拉筛选组织
    '$store.state.invoiceIssue.CurrentOrganization': {
      handler (value) {
        this.queryForm.orgId = value
        this.handleQuery()
        this.handleInitEquipment()
      },
      immediate: true
    }
  },
  computed: {
    selectedAmount () {
      let amount = this.mergeBillData.map(i => i.amount).reduce((num, sum) => Number(num) + Number(sum), 0)
      return amount.toFixed(2)
    },
    selectedTaxAmount () {
      let taxAmount = this.mergeBillData.map(i => i.taxAmount).reduce((num, sum) => Number(num) + Number(sum), 0)
      return taxAmount.toFixed(2)
    },
    selectedSumAmount () {
      let sumAmount = this.mergeBillData.map(i => i.sumAmount).reduce((num, sum) => Number(num) + Number(sum), 0)
      return sumAmount.toFixed(2)
    }
  },
  created () {
    this.handleInitDictionaries()
    this.handleInitEquipment()
  },
  methods: {
    // 初始化开票设备
    async handleInitEquipment () {
      if (!this.queryForm.orgId) {
        return
      }
      const { success, data } = await getEquipmentByOrgId({ orgId: this.queryForm.orgId })
      if (success) {
        this.billingChannel = data.billingChannel
      }
    },
    // 初始化字典值
    handleInitDictionaries () {
      this.getDicts('open_bill_type').then(({ data }) => this.openBillType = data)
    },
    // 收起筛选列表
    handleListClose () {
      this.selectExpended = false
    },
    // 展开筛选列表
    handleListShow () {
      this.selectExpended = true
    },
    // 重置筛选列表
    handleReset () {
      this.resetForm('queryForm')
    },
    // 表格值格式化
    handleTableValueFormat (row, column) {
      let value = row[column.property]
      switch (column.label) {
        case '金额':
        case '税额':
        case '价税合计':
          return '￥' + value
        case '发票类型':
          return this.handleValueToLabel('BillingType', value)
        case '业务类型':
          return this.handleValueToLabel('BillingFrom', value)
        case '单据类型':
          return this.getDictLabel(this.openBillType, value)
        case '数据来源':
          return this.handleValueToLabel('SystemSource', value)
        default:
          return value
      }
    },
    // 条件查询
    handleQuery () {
      this.cancelSelectionChangeBatch()
      this.handleGetBillingTradeList()
      this.handleOriginalOrderStatistics()
    },
    /* 待开数据统计 */
    async handleOriginalOrderStatistics () {
      const { success, data } = await waitSummary(this.addDateRange(this.queryForm, this.createdRange, 'Created'))
      if (success) {
        this.summary = data[0]
      }
    },
    // 批量删除
    handleBatchDelete () {
      this.deleteInvoices = [...this.mergeBillData]
      setTimeout(() => {
        this.$refs.invoiceDelete.open()
      }, 0)
    },
    // 删除成功
    handleDeleteSuccess () {
      this.handleQuery()
    },
    // 批量开具
    handleBatchBill () {
      if (this.mergeBillData.length < 1) {
        this.toast('请至少勾选一条发票', 'warning')
        return
      }
      this.billBatchVisible = true
    },
    // 待开发票列表获取
    async handleGetBillingTradeList () {
      this.recordsLoading = true
      if (this.ids) {
        this.queryForm.ids = JSON.parse(this.ids)
      }
      this.queryForm.electron = true;
      const { success, data } = await waitList(this.addDateRange(this.queryForm, this.createdRange, 'Created'))
      this.recordsLoading = false
      if (success) {
        this.tableData = data.records
        this.dataTotal = data.total
      }
    },
    // 发票开具
    async handleBillingInvoice (printable, back) {
      //判断连开带打的标识
      if (printable == 'Y') {
        this.issueData.printable = true
      } else {
        this.issueData.printable = false
      }
      const { success, data } = await billInvoice({
        id: this.issueData.id,
        printable: this.issueData.printable
      })
      if (success) {
        if (data.isSync) {
          this.billingInvoiceId = data.billingInvoiceId

          if (data.isPrint) {
            if (data.billingState) {
              this.billingReturnInfo = '发票开具成功！'
              this.billingReturnInfoVisible = true
              if (data.printState) {
                this.printInfo = '正在驱动打印机执行打印操作，请稍后......'
              } else {
                this.printInfo = '驱动打印机失败：根据接口返回原因进行展示'
              }
            } else {
              this.printInfo = '错误原因：根据接口返回原因进行展示！'
              this.billingInfo = '你可以在"开票记录"中重试。'
              this.billingReturnInfo = '发票开具失败！'
              this.buttonShow = true
              this.billingReturnInfoVisible = true
            }
          }
        }
        if (back) back(success)
      }
      this.handleGetBillingTradeList()
    },
    // 选中数据
    handleSelectionChange (invoices) {
      this.mergeBillData = invoices
    },
    // 取消选中
    cancelSelectionChangeBatch () {
      this.mergeBillData = []
      let retailTable = this.$refs.retailTable
      if (retailTable) retailTable.clearSelection()
    },
    // 合并发票
    handleMergeInvoice () {
      setTimeout(() => {
        this.$refs.invoiceMerge.open()
      }, 0)
    },
    // 导出数据
    async exportTemplate () {
      if (this.mergeBillData.length < 1) {
        this.toast('没有可导出的数据', 'warning')
        return
      }
      const rsp = await exportElectronInvoice(this.mergeBillData.map(item => item.id))
      this.downloadFile(rsp)
    },
    // 导入发票
    importTemplate () {
      this.$refs.importTemplate.open()
    },
    // 下载模板
    handleTemplate () {
      this.$refs.invoiceTmpl.open()
    },
    handleCloseUploadDialog () {
      this.uploadVisible = false
      this.handleQuery()
    },
    // 导入开票
    handleImport () {
      this.uploadVisible = true
    },
    // 发票明细
    handleOpenDetails (row, column) {
      if (['序号'].indexOf(column.label) == -1) {
        if ([undefined, '操作'].indexOf(column.label) == -1) {
          this.toast('点击“序号”列表区域，查看该票明细信息！', 'info')
        }
        return
      }

      this.invoiceId = row.id
      this.billingFrom = row.billingFrom
      setTimeout(() => {
        this.$refs.invoiceSplit.open()
      }, 0)
    },
    // 明细弹窗关闭
    handleInvoiceSplitSuccess () {
      this.$refs.retailTable.clearSelection()
      this.handleQuery()
    },
    // 切换分页条数
    handleSizeChange (value) {
      this.queryForm.size = value
      this.handleQuery()
    },
    // 翻页
    handleCurrentChange (value) {
      this.queryForm.page = value
      this.handleQuery()
    },
    // 记住选中
    handleGetRowKey (row) {
      return row.id
    },
    // 发票限额校验
    async handleCheckInvoiceLimit (invoice) {
      const { success, data } = await checkInvoiceLimit(invoice)
      if (success && data.success) {
        this.handleInvoiceIssue(invoice)
        return
      }
      this.limitVisible = true
      this.limit = { ...invoice, ...data }
    },
    // 发票开具
    handleInvoiceIssue (invoice) {
      if (invoice.sumAmount == 0) {
        this.toast('当前价税合计为0，无法开具！', 'warning')
        return
      }
      this.issueVisible = true
      this.issueData = invoice
      this.issueData['billingChannel'] = this.billingChannel
    },
    // 手动拆分
    handleManualSplit () {
      this.limitVisible = false
      this.invoiceId = this.limit.id
      setTimeout(() => {
        this.$refs.invoiceSplit.open()
      }, 0)
    },
    // 自动拆分
    handleAutomaticSplit () {
      this.limitVisible = false

      splitInvoiceLimit(this.limit.id).then(res => {
        if (res.success) {
          this.handleQuery()
          this.toast('拆分发票成功！', 'success')
        }
      })
    },
    // 批量下载发票
    batchDownloadInvoice (invoices) {
      let invoice = invoices.find(invoice => invoice.sumAmount() == 0)
      if (invoice != null) {
        this.toast('当前结算单中存在价税合计为0数据,无法开具', 'warming')
        return
      }
      let loading = this.handleLoading()
      getInventory({
        'orgId': invoice.orgId,
        'equipmentId': invoice.equipmentId,
        'billingType': invoice.billingType
      }).then(res => {
        loading.close()
        if (res.success) {
          this.issueVisible = true
          this.issueData = invoice
          this.issueData.totals = res.data.residueQuantity
          this.issueData.invoiceCode = res.data.invoiceCode
          this.issueData.invoiceNo = res.data.invoiceNo
        }
      })
    },
    // 发票抬头修改
    handleInvoiceTitle (invoice) {
      this.invoiceTitle = JSON.parse(JSON.stringify(invoice))
      this.invoiceTitleVisible = true
    },
    // 发票删除检查
    handleInvoiceDelete (invoice) {
      this.deleteInvoices = [invoice]
      setTimeout(() => {
        this.$refs.invoiceDelete.open()
      }, 0)
    },
    // 发票详情
    handleDetailInvoice (invoice) {
      this.drawer = !this.drawer
      selectInvoice(invoice.id).then(res => {
        if (res.success) {
          this.invoice = res.data
          this.invoice.industry = 'retail'
          this.invoice.items.forEach(item => item.taxRate = Number(item.taxRate) * 100 + '%')
          // 统计信息
          let sumAmount = this.invoice.items.map(item => Number(item.amount)).reduce((sum, amount) => sum + amount, 0)
          let sumTaxAmount = this.invoice.items.map(item => Number(item.taxAmount)).reduce((sum, taxAmount) => sum + taxAmount, 0)
          let total = {
            merchandiseName: '合计',
            amount: Number(sumAmount).toFixed(2),
            taxAmount: Number(sumTaxAmount).toFixed(2),
          }
          this.invoice.items.push(total)
          let sum = Number(sumAmount + sumTaxAmount).toFixed(2)
          let sumBig = this.handleSmallToCapitalize(sum)
          let priceTax = {
            merchandiseName: '价税合计（大写）',
            specification: sumBig,
            unit: '（小写）￥' + sum,
          }
          this.invoice.items.push(priceTax)
          this.invoice.sendBackInfo = res.data
        }
      })
    },
    fmtBillingType (val) {
      return this.handleValueToLabel('BillingType', val)
    },
    closeBillingReturnInfo () {
      this.handleQuery()
      this.issueVisible = false
      this.billingReturnInfoVisible = false
    },
    handleBatchBillSuccess () {
      this.handleQuery()
    },
    handleCloseBillDialog () {
      this.billBatchVisible = false
    },
    navigateToAlready () {
      this.issueVisible = false
      this.billingReturnInfoVisible = false
      this.$emit('navigateToAlready')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/style/select.scss";

  .content-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100% - 180px);

    .main-top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;

      span {
        font-size: 16px;
        color: #666666;
      }

      .el-button {
        width: 80px;
        padding: 0;
      }
    }

    .content-table {
      .el-button {
        padding: 0;
      }
      .table-statistics {
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #ebf4ff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;

        .statistics-left {
          display: flex;
          justify-content: space-between;

          div {
            margin-right: 20px;
          }

          div:first-child {
            margin-left: 10px;
          }
        }

        .statistics-right {
          display: flex;
          justify-content: space-between;

          div {
            margin-left: 20px;
          }

          div:last-child {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .dialog-content {
    margin-top: -6px;
    margin-left: 24px;
    margin-right: 24px;
    text-align: left;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
  }

  .dialog-body {
    margin: -16px 24px 0 24px;

    .body-top {
      .top-first-row {
        display: flex;
        margin-bottom: 12px;
      }

      .top-second-row {
        display: flex;
        text-align: left;
        margin-bottom: 24px;

        span {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 20em;
        }
      }

      label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }

      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
    }

    .body-bottom {
      text-align: left;
      border-top: 1px solid #e9e9e9;
      margin: 0 -24px;
      padding: 24px;

      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f5222d;
        line-height: 20px;
      }
    }
  }

  .limit-dialog {
    margin-top: -20px;
    padding-left: 20px;
    text-align: left;
    font-size: 14px;

    .data {
      padding-bottom: 25px;
      border-bottom: 1px solid #e9e9e9;

      span {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
      }
    }

    .message {
      padding: 24px 0;
      color: #f5222d;
    }
  }

  .el-dialog__footer {
    padding: 10px 24px 24px;
    text-align: right;
    box-sizing: border-box;
  }
</style>

